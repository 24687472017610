import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';

// RTK Queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {
  useCreateAdditionalEmailMutation,
  useDeleteAdditionalEmailMutation,
  useGetAdditionalEmailsQuery,
  useUpdateAccountSettingsMutation,
  useVerifyAdditionalEmailMutation,
  useResendAdditionalEmailVerificationMutation,
} from '@compt/app/services/api/accounts-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';

// Hooks and methods
import {FieldValues, useForm} from 'react-hook-form';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';

// Types
import {AccountSettingsUpdate, TimeZone} from '@compt/types/account';

// Components
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {ComptButton, ComptButtonType} from '../compt-button/compt-button';
import {ComptCheckboxField} from '../forms/compt-checkbox-field/compt-checkbox-field';
import {ComptEmailTagInput} from '../forms/compt-tag-inputs/compt-email-tag-input';
import {ComptLink} from '../compt-link/compt-link';
import {ComptSidePanel} from '../compt-side-panel/compt-side-panel';
import {ComptTextField} from '../forms/compt-text-field/compt-text-field';
import {ComptDropDownField} from '../forms/compt-dropdown-field/compt-dropdown-field';

interface UserAccountSettingsFormFieldValues extends FieldValues {
  email?: string;
  first_name: string;
  last_name: string;
  job_title?: string;
  nickname?: string;
  time_zone_code?: TimeZone;
  email_notification: boolean | string;
  slack_notification?: boolean | string;
}
interface AccountSettingsFormProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  verificationToken?: string | null;
  clearVerificationToken?: () => void;
}

export const UserAccountSettingsForm = (props: AccountSettingsFormProps) => {
  const {open} = props;

  const sessionQuery = useGetSessionQuery();
  const userData = sessionQuery.data;

  const companyQuery = useGetCompanyQuery(userData?.user_id ?? skipToken);
  const company = companyQuery?.data;

  const [updateAccountSettings, {isLoading: isUpdating}] = useUpdateAccountSettingsMutation();
  const {data: additionalEmailsData, refetch: refetchAdditionalEmails} =
    useGetAdditionalEmailsQuery();
  const [deleteAdditionalEmail] = useDeleteAdditionalEmailMutation();
  const [createAdditionalEmail] = useCreateAdditionalEmailMutation();
  const [verifyAdditionalEmail] = useVerifyAdditionalEmailMutation();
  const [shouldRefreshEmails, setShouldRefreshEmails] = useState(false);
  const [updateAdditionalEmail] = useResendAdditionalEmailVerificationMutation();
  const [hasAttemptedVerification, setHasAttemptedVerification] = useState(false);

  const formMethods = useForm<UserAccountSettingsFormFieldValues>({mode: 'onChange'});
  const {watch, reset, setError, clearErrors} = formMethods;

  const watchedSlackNotification = watch('slack_notification');
  const watchedEmailNotification = watch('email_notification');

  const [notificationSelected, setNotificationSelected] = useState(true);

  const submissionDisabled =
    isUpdating ||
    (userData?.can_enable_slack && !notificationSelected) ||
    !formMethods.formState.isValid;

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Reset form when closing, or setting to user data when opening side panel
  useEffect(() => {
    if (!open) {
      reset();
    }

    if (open && userData) {
      reset({
        email: userData?.email,
        nickname: userData?.nickname,
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        job_title: userData?.job_title,
        time_zone_code: {id: userData?.time_zone_code, label: userData?.time_zone_code},
        email_notification: userData?.email_notification,
        slack_notification: userData?.slack_notification,
        linkedin_account: userData?.linkedin_account,
      });
    }
  }, [open, reset, userData]);

  useEffect(() => {
    if (
      userData?.can_enable_slack &&
      watchedEmailNotification === false &&
      watchedSlackNotification === false
    ) {
      setError('slack_notification', {
        type: 'custom',
        message: 'At least one notification method is required',
      });
      setNotificationSelected(false);
      return;
    }

    setNotificationSelected(true);
    clearErrors(['slack_notification']);
  }, [watchedEmailNotification, watchedSlackNotification, userData, setError, clearErrors]);

  function onSubmit(form: UserAccountSettingsFormFieldValues) {
    const adjustedForm = form;

    if (userData?.user_may_set_timezone) {
      adjustedForm['user_timezone'] = form.time_zone_code?.id ?? userData.time_zone_code;
    } else {
      delete adjustedForm.user_timezone;
    }

    if (!userData?.has_slack_token) {
      delete adjustedForm.slack_notification;
    }

    const {time_zone_code, email, ...validFields} = adjustedForm;
    const submission = {...validFields} as AccountSettingsUpdate;

    updateAccountSettings(submission).then((data) => {
      if ('error' in data) {
        if ('data' in data.error) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errorData = data.error.data as any;
          if (errorData.errors.length > 0 && errorData.errors[0].includes('LinkedIn')) {
            formMethods.setError('linkedin_account', {message: errorData.errors[0]});
          }
        }
        console.error('Error updating account settings', {error: data, submitted: submission});
        triggerCustomToast('error', 'There was a problem updating your account settings');
        return;
      }
      props.setOpen(false);
      triggerCustomToast('success', 'Successfully updated your account settings');
      formMethods.reset();
    });
  }

  useEffect(() => {
    if (open || shouldRefreshEmails) {
      refetchAdditionalEmails();
      if (shouldRefreshEmails) {
        setShouldRefreshEmails(false);
      }
    }
  }, [open, shouldRefreshEmails, refetchAdditionalEmails]);

  useEffect(() => {
    if (open && verifyAdditionalEmail && props.verificationToken && !hasAttemptedVerification) {
      setHasAttemptedVerification(true);

      verifyAdditionalEmail({token: props.verificationToken})
        .unwrap()
        .then((data) => {
          const verifiedEmail = data.success;
          triggerCustomToast(
            'success',
            `Your email address ${
              verifiedEmail ? `"${verifiedEmail}"` : ''
            } has been verified successfully!`,
          );
          setShouldRefreshEmails(true);
          if (props.clearVerificationToken) {
            props.clearVerificationToken();
          }
        })
        .catch((error) => {
          console.error('Error verifying email:', error);
          const errorMessage = error?.data?.error || 'An error occurred during verification';
          triggerCustomToast('error', errorMessage);

          if (props.clearVerificationToken) {
            props.clearVerificationToken();
          }
        });
    }
  }, [
    open,
    verifyAdditionalEmail,
    props.verificationToken,
    props.clearVerificationToken,
    hasAttemptedVerification,
  ]);

  useEffect(() => {
    if (!open) {
      setHasAttemptedVerification(false);
    }
  }, [open]);

  const handleResendEmailVerification = (email: string) => {
    const emailToResendVerification = additionalEmailsData?.results.find(
      (item) => item.email === email,
    );

    if (emailToResendVerification) {
      updateAdditionalEmail({
        body: {id: emailToResendVerification.id, email: emailToResendVerification.email},
      }).then((result) => {
        if ('error' in result) {
          triggerCustomToast(
            'error',
            `Failed to send a verification email to "${emailToResendVerification.email}".
            Please try again.`,
          );
          console.error('Error deleting additional email', result.error);
        } else {
          triggerCustomToast(
            'success',
            `A verification link has been sent to "${emailToResendVerification.email}".`,
          );
        }
      });
    } else {
      triggerCustomToast(
        'error',
        'An email could not be found to resend a verification email to.' +
          ' Please contact support@compt.io if this persists.',
      );
      console.error('Could not find email to resend verification email to:', email);
    }
  };

  const handleDeleteAdditionalEmail = (email: string) => {
    const emailToDelete = additionalEmailsData?.results.find((item) => item.email === email);

    if (emailToDelete) {
      deleteAdditionalEmail(emailToDelete.id).then((result) => {
        if ('error' in result) {
          triggerCustomToast(
            'error',
            `Failed to remove "${emailToDelete.email}" email address. Please try again.`,
          );
          console.error('Error deleting additional email', result.error);
        } else {
          triggerCustomToast(
            'success',
            `Email address "${emailToDelete.email}" removed successfully`,
          );
        }
      });
    } else {
      console.error('Could not find email to delete:', email);
    }
  };

  const handleAddAdditionalEmail = (email: string) => {
    createAdditionalEmail({
      body: {email, verified: false},
    }).then((result) => {
      if ('error' in result) {
        triggerCustomToast('error', `Failed to add "${email}" email address. Please try again.`);
        console.error('Error adding additional email', result.error);
        const currentEmails = formMethods.getValues('teamEmails') || [];
        const updatedEmails = currentEmails.filter((e: string) => e !== email);
        formMethods.setValue('teamEmails', updatedEmails);
      } else {
        triggerCustomToast('success', `A verification link has been sent to ${email}.`);
        const currentEmails = formMethods.getValues('teamEmails') || [];
        const updatedEmails = currentEmails.filter((e: string) => e !== email);
        formMethods.setValue('teamEmails', updatedEmails);
      }
    });
  };

  if (!userData) {
    return null;
  }

  return (
    <ComptSidePanel open={props.open}>
      <ComptSidePanel.Header
        title="My Account"
        subtitle="Review and edit account details"
        setOpen={props.setOpen}
        headerIcon={{id: 'user-icon'}}
      />
      <ComptSidePanel.Content className="px-4 py-6 sm:px-6">
        <div className="pb-400">
          <ComptTextField
            name="email"
            label="Email"
            control={formMethods.control}
            register={formMethods.register}
            validation={{required: 'Email is required'}}
            disabled={true}
            errors={formMethods.formState.errors.email}
          />
          <ComptTextField
            name="nickname"
            label="Preferred name"
            subLabel={`This could be your first name or how you’d like people to refer to you.
            We always use your full name for HR and finance purposes.`}
            control={formMethods.control}
            register={formMethods.register}
            errors={formMethods.formState.errors.nickname}
          />
          <ComptTextField
            name="first_name"
            label="First name"
            control={formMethods.control}
            register={formMethods.register}
            validation={{required: 'First name is required'}}
            errors={formMethods.formState.errors.first_name}
          />
          <ComptTextField
            name="last_name"
            label="Last name"
            control={formMethods.control}
            register={formMethods.register}
            validation={{required: 'Last name is required'}}
            errors={formMethods.formState.errors.last_name}
          />
          <ComptTextField
            name="job_title"
            label="Job title"
            control={formMethods.control}
            register={formMethods.register}
            errors={formMethods.formState.errors.job_title}
          />
          <ComptDropDownField
            name="time_zone_code"
            data-testid="time_zone_field"
            label="Timezone"
            subLabel="Your local timezone."
            options={userData?.timezones}
            getKey={(tz: TimeZone) => tz.id}
            getDisplayText={(tz: TimeZone) => tz.label}
            disabled={!userData.user_may_set_timezone}
            by={(a: TimeZone, b: TimeZone) => a?.id === b?.id}
            register={formMethods.register}
            control={formMethods.control}
            errors={formMethods.formState.errors.time_zone_code}
          />
          {userData?.can_enable_slack && (
            <>
              <label className="flex body2 text-color-body1 mb-1">
                Notification channels <p className="text-stroke-critical ml-0.5">*</p>
              </label>
              <p className="body3 text-color-body2 mb-100">
                Select at least one way you would like us to communicate with you.
              </p>
              <ComptCheckboxField
                name="email_notification"
                label="Email"
                register={formMethods.register}
                errors={formMethods.formState.errors.email_notification}
                className="mt-200 mb-050"
              />
              <ComptCheckboxField
                name="slack_notification"
                label="Slack"
                register={formMethods.register}
                disabled={!userData.has_slack_token}
                errors={formMethods.formState.errors.slack_notification}
              />
            </>
          )}
          {!userData?.can_enable_slack && (
            <div>
              <hr className="my-6" />
              <label className="flex body2 text-color-body1 mb-1">Integrate with Slack</label>
              <p className="flex body3 text-color-body2 mb-100">
                Add Compt’s Slack app to your company’s workspace!{' '}
                <ComptLink className="ml-050" newTab={true} link="/slack/info">
                  Learn more
                </ComptLink>
              </p>
              <a
                // eslint-disable-next-line max-len
                href={`https://slack.com/oauth/v2/authorize?client_id=${company?.slack_client_id}&user_scope=channels:write,chat:write&state=${userData?.uid},/app&redirect_uri=${userData?.base_url}/slack`}
              >
                <ComptButton
                  className="w-1/4 my-300"
                  buttonType={ComptButtonType.OUTLINED}
                  iconId="slack-icon"
                >
                  Add to Slack
                </ComptButton>
              </a>
            </div>
          )}
          {featureEnabled(FeatureFlags.ENABLE_LEARNING_AND_DEVELOPMENT) && (
            <div>
              <hr className="my-6" />
              <label className="flex items-center body2 text-color-body1 mb-1">
                Show your LinkedIn Profile
              </label>
              <p className="block body3 text-color-body2 mb-4">
                Add your LinkedIn URL if you would like your profile to be visible to other Compt
                users (across companies) on the Recommendations feed.
              </p>
              <ComptTextField
                name="linkedin_account"
                label="LinkedIn URL"
                control={formMethods.control}
                register={formMethods.register}
                errors={formMethods.formState.errors.linkedin_account}
                placeholder="https://"
              />
            </div>
          )}
          {featureEnabled(FeatureFlags.EMAIL_TO_DRAFT) && (
            <div>
              <hr className="my-6" />
              <label className="flex items-center body2 text-color-body1 mb-1">
                Enabling email receipts
              </label>
              <p className="block body3 text-color-body2 mb-4">
                Add email addresses from which you can forward receipts. Claims submitted this way
                still require you to review and submit the claim through the Compt platform.
              </p>
              <ComptEmailTagInput
                name="teamEmails"
                label="Email address"
                control={formMethods.control}
                register={formMethods.register}
                setError={formMethods.setError}
                clearError={formMethods.clearErrors}
                getValues={formMethods.getValues}
                errors={formMethods.formState.errors.teamEmails}
                onResendVerification={handleResendEmailVerification}
                onAddTag={handleAddAdditionalEmail}
                onRemoveAdditionalEmail={handleDeleteAdditionalEmail}
                additionalEmails={additionalEmailsData?.results}
                emailValidationRegex={emailRegex}
                placeholder="e.g. john@example.com"
                validation={{
                  pattern: {
                    value: emailRegex,
                    message: 'Please enter a valid email address.',
                  },
                }}
              />
            </div>
          )}
        </div>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <ComptButton
          data-testid="account-settings-submit-button"
          buttonType={ComptButtonType.PRIMARY}
          className="min-w-fit"
          disabled={submissionDisabled}
          onClick={formMethods.handleSubmit((form) => onSubmit(form))}
          onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        >
          Save changes
        </ComptButton>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
